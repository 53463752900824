import {
  Button,
  Checkbox,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import TogetherCounter from "../Components/TogetherCounter";
import NavBar from "../Components/NavBar";
import homeLogo from "../Assets/home.svg";
import heart from "../Assets/heart.png";
import Confetti from "react-confetti";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { Link } from "react-router-dom";

const Home = () => {
  const [showConfetti, setShowConfetti] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState();
  const [docId, setDocId] = useState<string>("EdnICDzse1qr6xLNjKZd");

  useEffect(() => {
    onOpen();
    setShowConfetti(true);
    const fetchShow = async () => {
      const showCollection = collection(db, "popup");
      const showSnapshot = await getDocs(showCollection);
      const showData = showSnapshot.docs.map((doc) => {
        setDocId(doc.id);
        return doc.data().show;
      });
      setShow(showData[0]);
    };
    fetchShow();
  }, [onOpen, show]);

  const handleCheckboxChange = async () => {
    const showDoc = doc(db, "popup", docId);
    await updateDoc(showDoc, {
      show: false,
    });
  };

  const handleClick = async () => {
    const showDoc = doc(db, "popup", docId);
    await updateDoc(showDoc, {
      show: true,
    });
    onOpen();
  };

  return (
    <Flex bg="green.50" flexDir="column" minH="100vh" width="full">
      {showConfetti && show && (
        <Confetti width={window.outerWidth - 20} recycle={false} />
      )}
      {show && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bg="green.50" mt="100px" width="80%">
            <ModalCloseButton />
            <ModalBody textAlign="center" color="green.400">
              <Flex flexDir="column" align="center" p="5">
                <Text fontSize="40px">hi grace!</Text>
                <Image src={heart} alt="heart" boxSize="100px" my="40px" />
                <Text fontSize="20px">
                  happy 2-year anniversary! i love you so much and i hope you
                  enjoy this website that i made for you.
                </Text>
                <Checkbox
                  colorScheme="green"
                  mt="20px"
                  onChange={handleCheckboxChange}
                >
                  don't show again
                </Checkbox>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="green" mr={3} onClick={onClose}>
                close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <NavBar />
      <Flex flexDir="column" align="center" mt={{ base: "0px", md: "40px" }}>
        <Image
          src={homeLogo}
          alt="home"
          boxSize="500px"
          maxW="80%"
          mb={{ base: "-100px", md: "0px" }}
        />
        <TogetherCounter startDate="2022-06-09T00:10:00" />
        <Text
          as={Link}
          color="green.400"
          fontSize="15px"
          mt="50px"
          onClick={handleClick}
        >
          click here to show popup again
        </Text>
      </Flex>
    </Flex>
  );
};

export default Home;
