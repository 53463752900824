import { Flex, Image, Text, Box, keyframes } from "@chakra-ui/react";
import NavBar from "../Components/NavBar";
import memories from "../Assets/memories.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import Year from "../Components/Year";
import jun22 from "../Assets/year1/jun22.jpg";
import jul22 from "../Assets/year1/jul22.jpg";
import aug22 from "../Assets/year1/aug22.jpg";
import sep22 from "../Assets/year1/sep22.jpg";
import oct22 from "../Assets/year1/oct22.jpg";
import nov22 from "../Assets/year1/nov22.jpg";
import dec22 from "../Assets/year1/dec22.jpg";
import jan23 from "../Assets/year1/jan23.jpg";
import feb23 from "../Assets/year1/feb23.jpg";
import mar23 from "../Assets/year1/mar23.jpg";
import apr23 from "../Assets/year1/apr23.jpg";
import may23 from "../Assets/year1/may23.jpg";
import jun23 from "../Assets/year2/jun23.jpg";
import jul23 from "../Assets/year2/jul23.jpg";
import aug23 from "../Assets/year2/aug23.jpg";
import sep23 from "../Assets/year2/sep23.jpg";
import oct23 from "../Assets/year2/oct23.jpg";
import nov23 from "../Assets/year2/nov23.jpg";
import dec23 from "../Assets/year2/dec23.jpg";
import jan24 from "../Assets/year2/jan24.jpg";
import feb24 from "../Assets/year2/feb24.jpg";
import mar24 from "../Assets/year2/mar24.jpg";
import apr24 from "../Assets/year2/apr24.jpg";
import may24 from "../Assets/year2/may24.jpg";

const year1photos = [
  jun22,
  jul22,
  aug22,
  sep22,
  oct22,
  nov22,
  dec22,
  jan23,
  feb23,
  mar23,
  apr23,
  may23,
];
const year1text = [
  "our first 'date' (hangout)! playing clue then watching stitch then going to see the planets align",
  "making custom tote bags together while lying on the green",
  "me driving you around to say goodbye to your friends, and us sitting on the bench in depot square",
  "facetiming each other while doing work, and we created a heart out of our hands",
  "me visiting you in baltimore for the first time, and the flowers i got you",
  "us at gong cha after getting the lego bonsai tree, then going back to your house and building it with alice",
  "us messing around in your new car after your dad crashed the old one",
  "you visiting me for the first time, and us overlooking campus from the library balcony",
  "me visiting you in baltimore again, and us studying together in the computer lab",
  "me visiting you in baltimore for the third time, and finally reuniting stitch and coconut",
  "you being a silly goofball on facetime and dancing in the bathroom",
  "us being silly and messing around with the cheese pull from the korean corndogs from hmart",
];
const year2photos = [
  jun23,
  jul23,
  aug23,
  sep23,
  oct23,
  nov23,
  dec23,
  jan24,
  feb24,
  mar24,
  apr24,
  may24,
];
const year2text = [
  "us going to our first concert together (dermot kennedy)! not pictured: us being the only two poc in the venue",
  "us at the lexington carnival, and getting our new instagram profile pictures",
  "you testing positive for covid and me trying to cheer you up",
  "me visiting you to 'do the hackathon', and us eating a lot of good food and desserts",
  "you being the silliest goose on facetime",
  "you seeing me for the frisbee formal and us being all dressed up",
  "us being silly with teddy and enjoying our winter break",
  "building a tent to eat a potluck of lexington restaurant dishes and watching mamma mia!",
  "me visiting you and us reuniting stitch and coconut, goofy family picture",
  "us playing pokemon go in baltimore waiting for our aquarium reservation",
  "you being a silly goose with coconut on facetime",
  "our first meal together after our second year of school right after i got back from vacation and right before you left for your internship",
];

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Memories = () => {
  const [year, setYear] = useState(1);

  return (
    <Flex
      bg="green.50"
      minH="100vh"
      width="full"
      align="center"
      flexDir="column"
    >
      <NavBar />
      <Image
        src={memories}
        alt="memories"
        maxWidth="300px"
        objectFit="contain"
        mt="120px"
      />
      <Flex width="200px" justify="space-between" mt="20px">
        <Flex
          as={Link}
          onClick={() => setYear(1)}
          color="green.600"
          fontSize="28px"
          textAlign="center"
          justify="center"
          position="relative"
          _hover={{
            _after: {
              width: year === 1 ? "100%" : "0",
            },
          }}
          _after={{
            content: '""',
            position: "absolute",
            bottom: "0",
            left: "0",
            width: year === 1 ? "100%" : "0",
            height: "2px",
            rounded: "md",
            bg: "green.600",
            transition: "width 0.3s ease",
          }}
        >
          <Text>year 1</Text>
        </Flex>
        <Flex
          as={Link}
          onClick={() => setYear(2)}
          color="green.600"
          fontSize="28px"
          textAlign="center"
          justify="center"
          position="relative"
          _hover={{
            _after: {
              width: year === 2 ? "100%" : "0",
            },
          }}
          _after={{
            content: '""',
            position: "absolute",
            bottom: "0",
            left: "0",
            width: year === 2 ? "100%" : "0",
            height: "2px",
            rounded: "md",
            bg: "green.600",
            transition: "width 0.3s ease",
          }}
        >
          <Text>year 2</Text>
        </Flex>
      </Flex>
      <Box
        key={year}
        animation={`${fadeIn} 0.5s ease`}
        width="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {year === 1 ? (
          <Year year={22} photos={year1photos} text={year1text} />
        ) : (
          <Year year={23} photos={year2photos} text={year2text} />
        )}
      </Box>
    </Flex>
  );
};

export default Memories;
