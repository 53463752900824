import { Flex } from "@chakra-ui/react";

import MonthCard from "./MonthCard";

const YearOne = ({
  year,
  photos,
  text,
}: {
  year: number;
  photos: string[];
  text: string[];
}) => {
  return (
    <Flex
      color="green.400"
      fontSize="20px"
      justify="space-evenly"
      width="90%"
      wrap="wrap"
      mt="20px"
    >
      <MonthCard month={`june '${year}`} link={photos[0]} text={text[0]} />
      <MonthCard month={`july '${year}`} link={photos[1]} text={text[1]} />
      <MonthCard month={`august '${year}`} link={photos[2]} text={text[2]} />
      <MonthCard month={`september '${year}`} link={photos[3]} text={text[3]} />
      <MonthCard month={`october '${year}`} link={photos[4]} text={text[4]} />
      <MonthCard month={`november '${year}`} link={photos[5]} text={text[5]} />
      <MonthCard month={`december '${year}`} link={photos[6]} text={text[6]} />
      <MonthCard
        month={`january '${year + 1}`}
        link={photos[7]}
        text={text[7]}
      />
      <MonthCard
        month={`february '${year + 1}`}
        link={photos[8]}
        text={text[8]}
      />
      <MonthCard month={`march '${year + 1}`} link={photos[9]} text={text[9]} />
      <MonthCard
        month={`april '${year + 1}`}
        link={photos[10]}
        text={text[10]}
      />
      <MonthCard month={`may '${year + 1}`} link={photos[11]} text={text[11]} />
    </Flex>
  );
};

export default YearOne;
