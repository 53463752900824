import { Flex } from "@chakra-ui/react";
import { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import SongCard from "./SongCard";

const SongCarousel = ({ songs }: { songs: any[] }) => {
  const [goToSlide, setGoToSlide] = useState<number | undefined>(undefined);

  const cards = songs.map((song: any, index: number) => ({
    key: index,
    content: (
      <SongCard
        key={index}
        song={song.track.name}
        artist={song.track.artists[0].name}
        image={song.track.album.images[0].url}
      />
    ),
    onClick: () => setGoToSlide(index),
  }));

  return (
    <Flex width="100%">
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={2}
        showNavigation={false}
        animationConfig={config.gentle}
      />
    </Flex>
  );
};

export default SongCarousel;
