import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const MonthCard = ({
  month,
  link,
  text,
}: {
  month: string;
  link: string;
  text: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg="green.50"
          color="green.600"
          maxW={{ base: "90%", md: "40%" }}
        >
          <ModalHeader>{month}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={link} alt={month} />
            <Text mt="20px" color="green.400" mb="20px">
              {text}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Text
        as={Link}
        onClick={onOpen}
        position="relative"
        _hover={{
          _after: {
            width: "100%",
          },
        }}
        _after={{
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "0",
          height: "1.5px",
          rounded: "md",
          bg: "green.400",
          transition: "width 0.3s ease",
        }}
      >
        {month}
      </Text>
    </Flex>
  );
};

export default MonthCard;
