import { Button, Flex, Image, Text } from "@chakra-ui/react";
import LetterSVG from "../Assets/letter.svg";
import { Link as RouterLink } from "react-router-dom";

const wiggle = `
  @keyframes wiggle {
    0%, 100% { transform: rotate(0deg); }
    3.125% { transform: rotate(5deg); }
    9.375% { transform: rotate(-2deg); }
    12.5% { transform: rotate(0deg); }
    18.75% { transform: rotate(0deg); }
    21.875% { transform: rotate(2deg); }
    28.125% { transform: rotate(-5deg); }
    31.25% { transform: rotate(0deg); }
  }
`;

const Letter = () => {
  return (
    <Flex bg="green.50" w="full" h="100vh">
      <Flex flexDir="column" align="center" w="full">
        <style>{wiggle}</style>
        <Button
          zIndex="1"
          height="207px"
          width="341px"
          mt="246px"
          bg="transparent"
          _hover={{ bg: "transparent" }}
          color="transparent"
          as={RouterLink}
          to="/home"
        />
        <Text fontSize="40px" color="green.400" mt="10px">
          click me!
        </Text>
        <Image
          position="fixed"
          src={LetterSVG}
          alt="letter"
          boxSize="700px"
          animation="wiggle 2s infinite"
        />
      </Flex>
    </Flex>
  );
};

export default Letter;
