import { Flex, Text } from "@chakra-ui/react";
import NavBar from "../Components/NavBar";
import Map, {
  FullscreenControl,
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Pin from "../Components/Pin";
import { useMemo, useState } from "react";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoibWF0dHJ3YW5nIiwiYSI6ImNsd3NjaWd4bjBhbzQycXEwZXAza3RoZWUifQ.hAraW9T2HF943sGeSj14oQ";

const visitedLocations = [
  {
    city: "philadelphia",
    state: "pennsylvania",
    latitude: 40.0094,
    longitude: -75.1333,
    text: "we took a day trip here and explored the city!",
  },
  {
    city: "san francisco",
    state: "california",
    latitude: 37.7751,
    longitude: -122.4193,
    text: "we visited chinatown and japantown, and ate some amazing food!",
  },
  {
    city: "seattle",
    state: "washington",
    latitude: 47.6205,
    longitude: -122.3509,
    text: "you are interning here and i visited you!",
  },
  {
    city: "lexington",
    state: "massachusetts",
    latitude: 42.48065,
    longitude: -71.24,
    text: "you live here and we have spent soo much time at your house and in the center!",
  },
  {
    city: "boston",
    state: "massachusetts",
    latitude: 42.3399,
    longitude: -71.0899,
    text: "i go to school here, and we have had amazing food, shopping trips, and museum visits!",
  },
  {
    city: "wilmington",
    state: "massachusetts",
    latitude: 42.54102,
    longitude: -71.19235,
    text: "i live here and you have visited me many times!",
  },
  {
    city: "baltimore",
    state: "maryland",
    latitude: 39.3299,
    longitude: -76.6205,
    text: "you go to school here, and we have explored the area together!",
  },
  {
    city: "oakland",
    state: "california",
    latitude: 37.7806,
    longitude: -122.1826,
    text: "i went to school here for a year, and you came to visit me!",
  },
];

const MapPage = () => {
  const [popupInfo, setPopupInfo] = useState<any>(null);

  const pins = useMemo(
    () =>
      visitedLocations.map((city, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={city.longitude}
          latitude={city.latitude}
          anchor="bottom"
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setPopupInfo(city);
          }}
        >
          <Pin />
        </Marker>
      )),
    []
  );

  return (
    <Flex flexDir="column" align="center" bg="green.50" minH="100vh">
      <NavBar />
      <Map
        initialViewState={{
          latitude: 40,
          longitude: -100,
          zoom: 3.5,
          pitch: 0,
          bearing: 0,
        }}
        style={{ width: "80vw", height: "80vh", marginTop: "100px" }}
        mapStyle="mapbox://styles/mattrwang/clwse0rlc05ee01nx6xe906b5"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />

        {pins}

        {popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
          >
            <Flex flexDir="column" fontFamily="sans-serif">
              <Text color="green.600">
                {popupInfo.city}, {popupInfo.state}
              </Text>
              <Text color="green.400">{popupInfo.text}</Text>
            </Flex>
          </Popup>
        )}
      </Map>
    </Flex>
  );
};

export default MapPage;
