import {
  Button,
  Checkbox,
  Flex,
  FormLabel,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import NavBar from "../Components/NavBar";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import Eat from "../Assets/eat.png";
import Travel from "../Assets/travel.png";
import { SmallCloseIcon } from "@chakra-ui/icons";

const Todo = () => {
  const toast = useToast();
  const [todos, setTodos] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [category, setCategory] = useState<string>("");
  const [thing, setThing] = useState<string>("");
  const categories = ["do", "eat", "watch", "visit"];

  useEffect(() => {
    const fetchTodos = async () => {
      const todosCollection = collection(db, "todo");
      const todosSnapshot = await getDocs(todosCollection);
      const todosData = todosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTodos(todosData);
    };
    fetchTodos();
  }, []);

  const handleCheckboxChange = async (id: string, currentStatus: boolean) => {
    const todoDoc = doc(db, "todo", id);
    await updateDoc(todoDoc, {
      completed: !currentStatus,
    });

    setTodos((prevTodos) =>
      prevTodos.map((todo) =>
        todo.id === id ? { ...todo, completed: !currentStatus } : todo
      )
    );
  };

  const handleRemoveTodo = async (id: string) => {
    const todoDoc = doc(db, "todo", id);
    await deleteDoc(todoDoc);

    setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
  };

  const handleAddTodo = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();

    if (!category || !thing.trim()) {
      if (!thing.trim()) {
        toast({
          title: "thing is required",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      }
      if (!category) {
        toast({
          title: "category is required",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom-right",
        });
      }
      return;
    }

    const newTodo = {
      category,
      thing: thing.trim(),
      completed: false,
    };

    const todosCollection = collection(db, "todo");
    const docRef = await addDoc(todosCollection, newTodo);

    setThing("");
    setCategory("");

    setTodos((prevTodos) => [...prevTodos, { id: docRef.id, ...newTodo }]);
    onClose();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter" || e.key === "Return") {
      e.preventDefault();
      handleAddTodo();
    }
  };

  const renderTodosByCategory = (category: string) => {
    const sortedTodos = todos
      .filter((todo) => todo.category === category)
      .sort((a, b) => a.completed - b.completed);

    return (
      <Flex
        flexDir="column"
        width={{ base: "full", md: "320px" }}
        textAlign="center"
        px={{ base: "10px", md: "0px" }}
      >
        <Text fontSize="30px" mb="8px" color="green.600">
          {category}
        </Text>
        {sortedTodos.map((todo) => (
          <Flex
            align="center"
            key={todo.id}
            textAlign="left"
            _hover={{ ".remove-btn": { opacity: 1 } }}
          >
            <Checkbox
              isChecked={todo.completed}
              colorScheme="green"
              onChange={() => handleCheckboxChange(todo.id, todo.completed)}
              mt="2px"
            />
            <Text fontSize="21px" ml="8px" flex="1">
              {todo.thing}
            </Text>
            <IconButton
              className="remove-btn"
              aria-label="Remove todo"
              icon={<SmallCloseIcon boxSize="18px" color="green.600" />}
              size="xs"
              onClick={() => handleRemoveTodo(todo.id)}
              bg="transparent"
              _hover={{ bg: "transparent" }}
              mt="1px"
              opacity="0"
              transition="opacity 0.2s"
            />
          </Flex>
        ))}
      </Flex>
    );
  };

  return (
    <Flex
      bg="green.50"
      width="full"
      flexDir="column"
      minH="100vh"
      align="center"
    >
      <NavBar />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg="green.50"
          color="green.600"
          maxW={{ base: "90%", md: "40%" }}
        >
          <ModalHeader>add item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleAddTodo} onKeyDown={handleKeyPress}>
              <FormLabel>category:</FormLabel>
              <RadioGroup
                onChange={setCategory}
                value={category}
                colorScheme="green"
              >
                <HStack>
                  <Radio value="do" mr="15px">
                    do
                  </Radio>
                  <Radio value="eat" mr="15px">
                    eat
                  </Radio>
                  <Radio value="watch" mr="15px">
                    watch
                  </Radio>
                  <Radio value="visit" mr="15px">
                    visit
                  </Radio>
                </HStack>
              </RadioGroup>
              <FormLabel mt="20px">thing:</FormLabel>
              <Input
                bg="white"
                value={thing}
                onChange={(e) => {
                  setThing(e.target.value);
                }}
              />
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleAddTodo}>
              add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex mt="60px" width="full" flexDir={{ base: "column", md: "row" }}>
        <Spacer />
        <Image
          src={Eat}
          alt="eat"
          maxWidth="300px"
          objectFit="contain"
          mx="50px"
        />
        <Image
          src={Travel}
          alt="travel"
          maxWidth="300px"
          objectFit="contain"
          mt="45px"
          mx="50px"
        />
        <Spacer />
      </Flex>
      <Flex
        as="button"
        onClick={onOpen}
        mt="20px"
        color="green.600"
        position="relative"
        _hover={{
          _after: {
            width: "100%",
          },
        }}
        _after={{
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "0",
          height: "2px",
          rounded: "md",
          bg: "green.600",
          transition: "width 0.3s ease",
        }}
      >
        <Text fontSize="30px">add</Text>
      </Flex>
      <Flex
        mt="12px"
        color="green.400"
        width="full"
        justify="space-evenly"
        mb="100px"
        flexDir={{ base: "column", md: "row" }}
      >
        {categories.map((category) => renderTodosByCategory(category))}
      </Flex>
    </Flex>
  );
};

export default Todo;
