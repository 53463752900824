import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import theme from "./theme";
import Home from "./Pages/Home";
import Letter from "./Pages/Letter";
import Memories from "./Pages/Memories";
import Todo from "./Pages/Todo";
import Songs from "./Pages/Songs";
import MapPage from "./Pages/MapPage";
import "./scrollbar.css";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<Letter />} />
        <Route path="/home" element={<Home />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/todo" element={<Todo />} />
        <Route path="/songs" element={<Songs />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  </ChakraProvider>
);
