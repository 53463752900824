import {
  Flex,
  Spacer,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";

const NavBar = () => {
  const [navColor, setNavColor] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  function scrollHandler() {
    if (window.scrollY >= 20) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  const NavLinks = () => (
    <>
      <Flex
        as={Link}
        to="/home"
        fontSize="30px"
        mx="20px"
        position="relative"
        _hover={{
          _after: {
            width: "100%",
          },
        }}
        _after={{
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "0",
          height: "2px",
          rounded: "md",
          bg: "green.400",
          transition: "width 0.3s ease",
        }}
      >
        home
      </Flex>
      <Flex
        as={Link}
        to="/memories"
        fontSize="30px"
        mx="20px"
        position="relative"
        _hover={{
          _after: {
            width: "100%",
          },
        }}
        _after={{
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "0",
          height: "2px",
          rounded: "md",
          bg: "green.400",
          transition: "width 0.3s ease",
        }}
      >
        memories
      </Flex>
      <Flex
        as={Link}
        to="/songs"
        fontSize="30px"
        mx="20px"
        position="relative"
        _hover={{
          _after: {
            width: "100%",
          },
        }}
        _after={{
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "0",
          height: "2px",
          rounded: "md",
          bg: "green.400",
          transition: "width 0.3s ease",
        }}
      >
        songs
      </Flex>
      <Flex
        as={Link}
        to="/todo"
        fontSize="30px"
        mx="20px"
        position="relative"
        _hover={{
          _after: {
            width: "100%",
          },
        }}
        _after={{
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "0",
          height: "2px",
          rounded: "md",
          bg: "green.400",
          transition: "width 0.3s ease",
        }}
      >
        bucket list
      </Flex>
      <Flex
        as={Link}
        to="/map"
        fontSize="30px"
        mx="20px"
        position="relative"
        _hover={{
          _after: {
            width: "100%",
          },
        }}
        _after={{
          content: '""',
          position: "absolute",
          bottom: "0",
          left: "0",
          width: "0",
          height: "2px",
          rounded: "md",
          bg: "green.400",
          transition: "width 0.3s ease",
        }}
      >
        map
      </Flex>
    </>
  );

  return (
    <Flex
      width="full"
      h="80px"
      bg={navColor ? "#e1fce8a9" : ""}
      transition="0.3s"
      shadow={navColor ? "md" : ""}
      backdropFilter={navColor ? "blur(15px)" : ""}
      position="fixed"
      color="green.400"
      align="center"
      zIndex="10"
    >
      <Spacer />
      {isMobile ? (
        <>
          <IconButton
            aria-label="menu"
            colorScheme="green"
            icon={<HamburgerIcon />}
            size="lg"
            mr="10px"
            onClick={onOpen}
          />
          <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
            <DrawerOverlay>
              <DrawerContent bg="green.50" color="green.400">
                <DrawerCloseButton />
                <DrawerHeader color="green.600">menu</DrawerHeader>
                <DrawerBody>
                  <Flex flexDir="column" align="center">
                    <NavLinks />
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </>
      ) : (
        <Flex>
          <NavLinks />
        </Flex>
      )}
    </Flex>
  );
};

export default NavBar;
