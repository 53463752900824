import { Flex, Image, Text } from "@chakra-ui/react";
import { useState } from "react";

const SongCard = ({
  song,
  artist,
  image,
}: {
  song: string;
  artist: string;
  image: string;
}) => {
  const [selected, setSelected] = useState(false);
  return (
    <Flex
      minH={{ base: "150px", md: "300px" }}
      w={{ base: "150px", md: "300px" }}
      bg="green.100"
      rounded="xl"
      flexDir="column"
      align="center"
      onMouseEnter={() => setSelected(true)}
      onMouseLeave={() => setSelected(false)}
      transform={selected ? "scale(1.24)" : "scale(1.2)"}
      boxShadow={
        selected ? "0 20px 25px rgb(0 0 0 / 25%)" : "0 2px 10px rgb(0 0 0 / 8%)"
      }
      transition="all 0.2s"
      textAlign="center"
    >
      <Image
        src={image}
        alt={song}
        boxSize={{ base: "120px", md: "250px" }}
        rounded="lg"
        mt="-50px"
      />
      <Text fontWeight="bold" color="green.600" mt="10px" fontSize="20px">
        {song}
      </Text>
      <Text color="green.400" mb="10px">
        {artist}
      </Text>
    </Flex>
  );
};

export default SongCard;
