import { Flex, Button, Text } from "@chakra-ui/react";
import NavBar from "../Components/NavBar";
import { useEffect, useState } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SongCarousel from "../Components/SongCarousel";

const clientId = "ddcd6a90db634368840f50cc4a87f427";
const redirectUri = "https://www.gracenmatt.com/songs";

const Songs = () => {
  const [tracks, setTracks] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState<string>(checkStoredToken());

  function checkStoredToken() {
    const tokenData = localStorage.getItem("spotify_access_token_data");
    if (tokenData) {
      const { token, timestamp } = JSON.parse(tokenData);
      const currentTime = new Date().getTime();
      if (currentTime - timestamp < 3600000) {
        return token;
      } else {
        localStorage.removeItem("spotify_access_token_data");
      }
    }
    return "";
  }

  const storeTokenWithTimestamp = (token: string) => {
    const tokenData = {
      token,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(
      "spotify_access_token_data",
      JSON.stringify(tokenData)
    );
  };

  const getAccessToken = async (code: string) => {
    const clientSecret = "1f20365a844c45a786a29e5fcb300f51";
    const encodedCredentials = btoa(`${clientId}:${clientSecret}`);
    try {
      const response = await axios.post(
        "https://accounts.spotify.com/api/token",
        new URLSearchParams({
          grant_type: "authorization_code",
          code: code,
          redirect_uri: redirectUri,
        }),
        {
          headers: {
            Authorization: `Basic ${encodedCredentials}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      const token = response.data.access_token;
      setAccessToken(token);
      storeTokenWithTimestamp(token);
    } catch (error) {
      console.error("Failed to retrieve access token", error);
    }
  };

  const getPlaylistTracks = async (playlistId: string) => {
    const apiUrl = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`;

    try {
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return response.data.items;
    } catch (error) {
      console.error("Failed to retrieve playlist", error);
      return [];
    }
  };

  const handleLogin = () => {
    const authUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&scope=playlist-read-private%20user-modify-playback-state`;
    window.location.href = authUrl;
  };

  useEffect(() => {
    const fetchTracks = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code && !accessToken) {
        await getAccessToken(code);
      } else if (accessToken) {
        const playlistId = "74VIRaK9m5tcOMJdu86Cbs";
        const trackData = await getPlaylistTracks(playlistId);
        setTracks(trackData || []);
      }
    };

    fetchTracks();
  });

  return (
    <Flex bg="green.50" flexDir="column" minH="100vh" align="center">
      <NavBar />
      {!accessToken ? (
        <Flex pt="100px" justifyContent="center">
          <Button onClick={handleLogin} colorScheme="green" size="lg">
            login with spotify
          </Button>
        </Flex>
      ) : (
        <Flex mt={{ base: "300px", md: "350px" }} width="30%">
          <SongCarousel songs={tracks} />
        </Flex>
      )}
      <Text
        mt={{ base: "150px", md: "250px" }}
        color="green.600"
        fontSize="32px"
      >
        play our playlists!
      </Text>

      <Flex
        mt="30px"
        width="full"
        justify="space-evenly"
        mb="180px"
        flexDir={{ base: "column", md: "row" }}
        align="center"
      >
        <Flex
          width={{ base: "80%", md: "500px" }}
          flexDir="column"
          align="center"
        >
          <Text
            fontSize="25px"
            color="green.400"
            mb={{ base: "0px", md: "18px" }}
          >
            for us
          </Text>
          <iframe
            title="for us playlist"
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/74VIRaK9m5tcOMJdu86Cbs?utm_source=generator&theme=0"
            width="100%"
            height="352"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </Flex>
        <Flex
          width={{ base: "80%", md: "500px" }}
          flexDir="column"
          align="center"
          mt={{ base: "10px", md: "0px" }}
        >
          <Text
            fontSize="25px"
            color="green.400"
            mb={{ base: "0px", md: "18px" }}
          >
            our daily mix
          </Text>
          <iframe
            title="daily mix playlist"
            style={{ borderRadius: "12px" }}
            src="https://open.spotify.com/embed/playlist/37i9dQZF1EJNaPtZ4llPR6?utm_source=generator&theme=0"
            width="100%"
            height="352"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Songs;
