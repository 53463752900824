// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAuMnDTRaXITtGpc00L7N1-PXDKpcMjNl8",
  authDomain: "gracenmatt-3211d.firebaseapp.com",
  projectId: "gracenmatt-3211d",
  storageBucket: "gracenmatt-3211d.appspot.com",
  messagingSenderId: "727913449739",
  appId: "1:727913449739:web:88cf56e373646e4059ba0e",
  measurementId: "G-9EEKVXSGB2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, db };
