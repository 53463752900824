import React, { useEffect, useMemo, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";

const calculateTimeTogether = (startDate: Date) => {
  const now = new Date();
  const diff = now.getTime() - startDate.getTime();

  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));
  const days = Math.floor((diff / (1000 * 60 * 60 * 24)) % 365);
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  return { years, days, hours, minutes, seconds };
};

const TogetherCounter: React.FC<{ startDate: string }> = ({ startDate }) => {
  const start = useMemo(() => new Date(startDate), [startDate]);
  const [timeTogether, setTimeTogether] = useState(
    calculateTimeTogether(start)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeTogether(calculateTimeTogether(start));
    }, 1000);

    return () => clearInterval(interval);
  }, [start]);

  return (
    <Flex textAlign="center" flexDir="column" color="green.400" align="center">
      <Text fontSize="2xl" mb="10px">
        we have been together for...
      </Text>
      <Text fontSize="3xl">
        <Flex align="center" flexDir={{ base: "column", md: "row" }}>
          <Flex align="center">
            <Flex
              bg="#d6ffe3"
              rounded="lg"
              p="5px"
              m="8px"
              width="60px"
              justify="center"
            >
              {timeTogether.years}
            </Flex>
            year,
          </Flex>
          <Flex align="center">
            <Flex
              bg="#d6ffe3"
              rounded="lg"
              p="5px"
              m="8px"
              width="60px"
              justify="center"
            >
              {timeTogether.days}
            </Flex>
            days,
          </Flex>
          <Flex align="center">
            <Flex
              bg="#d6ffe3"
              rounded="lg"
              p="5px"
              m="8px"
              width="60px"
              justify="center"
            >
              {timeTogether.hours}
            </Flex>
            hours,
          </Flex>
          <Flex align="center">
            <Flex
              bg="#d6ffe3"
              rounded="lg"
              p="5px"
              m="8px"
              width="60px"
              justify="center"
            >
              {timeTogether.minutes}
            </Flex>
            minutes,
          </Flex>
          <Flex align="center">
            <Flex
              bg="#d6ffe3"
              rounded="lg"
              p="5px"
              m="8px"
              width="60px"
              justify="center"
            >
              {timeTogether.seconds}
            </Flex>
            seconds
          </Flex>
        </Flex>
      </Text>
    </Flex>
  );
};

export default TogetherCounter;
